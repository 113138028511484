import type { ElementType, ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface BumblebeeContext {
  NavLink: ElementType;
}

export interface BumblebeeProviderProps extends BumblebeeContext {
  children: ReactNode;
}

const BumblebeeContext = createContext<BumblebeeContext | undefined>(undefined);

export const useBumblebeeContext = (): BumblebeeContext => {
  const context = useContext(BumblebeeContext);

  /* istanbul ignore next */
  if (context === undefined) {
    throw new Error(
      'useBumblebeeContext must be used within a BumblebeeProvider',
    );
  }

  /* istanbul ignore next */
  if (context.NavLink === undefined) {
    throw new Error('NavLink must be added to BumblebeeProvider');
  }

  return context;
};

export const BumblebeeProvider = ({
  children,
  NavLink,
}: BumblebeeProviderProps) => {
  return (
    // eslint-disable-next-line @eslint-react/no-unstable-context-value
    <BumblebeeContext.Provider value={{ NavLink }}>
      {children}
    </BumblebeeContext.Provider>
  );
};
