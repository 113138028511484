import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import { Box } from '../box/box';

import * as styles from './loading-spinner.css';

export interface LoadingSpinnerProps {
  /**
   * Colour of spinner
   */
  color?: keyof typeof styles.color;
  /**
   * Size of spinner
   */
  size?: keyof typeof styles.size;
  /**
   * Add a wrapper to the element to center inside parent
   */
  center?: boolean;
}

export const TEST_IDS = {
  CONTAINER: 'loading-spinner',
  SVG: 'loading-spinner-svg-item',
  OUTER_WRAPPER: 'loading-spinner-wrapper',
};

const LoadingSpinnerInner = ({
  color = 'primary',
  size = 'medium',
}: LoadingSpinnerProps) => (
  <Box
    aria-hidden="true"
    data-testid={TEST_IDS.CONTAINER}
    className={classFilter([styles.container, styles.size[size]])}
  >
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="spinnerClip">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0461 1.87907C39.3151 0.735489 45.7694 1.15564 51.8373 3.10229C57.9052 5.04894 63.3993 8.46202 67.833 13.0392C72.2667 17.6163 75.5032 23.2163 77.2558 29.3431C77.8253 31.3343 76.6729 33.4102 74.6817 33.9798C72.6905 34.5494 70.6146 33.3969 70.045 31.4057C68.6316 26.4648 66.0216 21.9487 62.446 18.2574C58.8704 14.5661 54.4396 11.8137 49.5462 10.2438C44.6528 8.6739 39.4477 8.33507 34.392 9.25731C29.3364 10.1795 24.5862 12.3344 20.5622 15.531C16.5382 18.7275 13.3647 22.8671 11.3228 27.5831C9.2808 32.2991 8.43342 37.446 8.85568 42.5677C9.27793 47.6894 10.9568 52.6279 13.7436 56.9458C16.5303 61.2637 20.3389 64.8277 24.8321 67.3221C26.6428 68.3273 27.2958 70.6101 26.2906 72.4209C25.2853 74.2316 23.0025 74.8846 21.1918 73.8794C15.6203 70.7864 10.8976 66.367 7.44201 61.0128C3.98643 55.6586 1.90464 49.5348 1.38104 43.1839C0.85744 36.833 1.9082 30.4509 4.44023 24.6031C6.97226 18.7553 10.9074 13.6221 15.8971 9.65838C20.8868 5.69464 26.7771 3.02264 33.0461 1.87907Z"
          fill="url(#paint0_angular_3891_23526)"
        />
      </clipPath>

      <foreignObject
        x="0"
        y="0"
        width="100"
        height="100"
        clipPath="url(#spinnerClip)"
      >
        <div
          data-testid={TEST_IDS.SVG}
          className={classFilter([styles.svg, styles.color[color]])}
        />
      </foreignObject>
    </svg>
  </Box>
);

export const LoadingSpinner = ({ center, color, size }: LoadingSpinnerProps) =>
  center ? (
    <Box
      alignContent={{ x: 'center', y: 'center' }}
      expand
      className={styles.wrapper}
      data-testid={TEST_IDS.OUTER_WRAPPER}
    >
      <LoadingSpinnerInner color={color} size={size} />
    </Box>
  ) : (
    <LoadingSpinnerInner color={color} size={size} />
  );
