import type { ElementType, ReactNode } from 'react';

import * as styles from './screen-reader-text.css';

export interface ScreenReaderTextProps {
  children: ReactNode;
  Component?: ElementType;
  id?: string;
  'data-testid'?: string;
}

export const ScreenReaderText = ({
  children,
  Component = 'span',
  id,
  'data-testid': testId,
}: ScreenReaderTextProps) => (
  <Component className={styles.screenReaderOnly} id={id} data-testid={testId}>
    {children}
  </Component>
);
