import type { ElementType, ReactNode } from 'react';

import type { themeVars } from '@shieldpay/theme-provider-ui/theme/contract';
import type {
  PaddingX,
  PaddingY,
  Palette,
} from '@shieldpay/theme-provider-ui/theme/types';
import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import * as buttonStyles from './button.css';
import * as linkStyles from './link.css';

type TextVariant = keyof (typeof themeVars.typography)['variant'] | 'inherit';

export type LinkVariant = keyof typeof linkStyles.variant;
export type ButtonSize = 'small' | 'medium' | 'large';
export type LinkSize = 'inherit' | 'small' | 'medium';

interface Button {
  variant: keyof typeof buttonStyles.variant;
  size: ButtonSize;
}

interface Link {
  variant: LinkVariant;
  size: LinkSize;
}

export type LinkOrButtonExcludeIcon = {
  variant?: Exclude<LinkOrButton['variant'], 'icon'>;
  Icon?: never;
  iconColor?: never;
  LeftIcon?: ElementType;
  RightIcon?: ElementType;
  loading?: boolean;
  children: ReactNode;
  'aria-labelledby'?: string;
};

export type LinkOrButtonIcon = {
  variant: 'icon';
  /**
   * Icon is required when variant is set to icon.
   * This is so the icon size and padding size are set.
   */
  Icon: ElementType;
  /**
   * Icon color is optional when variant is set to icon.
   * example of use: modal close button
   */
  iconColor?: Palette;
  LeftIcon?: never;
  RightIcon?: never;
  loading?: never;
} & (
  | { 'aria-labelledby': string; children?: never }
  | { 'aria-labelledby'?: never; children: ReactNode }
);

export type ButtonOrLinkComponentProps = {
  disabled?: boolean;
  size?: ButtonSize | LinkSize;
} & (LinkOrButtonExcludeIcon | LinkOrButtonIcon);

export type LinkOrButton = Button | Link;

type GetClickableElementStylesOptions = LinkOrButton & {
  disabled?: boolean;
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
};

/**
 * When the variant is not a 'link' we need to map the button size to correct text element.
 */
export const textVariant: Record<string, TextVariant> = {
  small: 'captionMedium',
  medium: 'bodyMedium',
  large: 'title',
};

export const linkTextVariant: Record<string, TextVariant> = {
  small: 'caption',
  large: 'body',
};

const isButton = (variantAndSize: Button | Link): variantAndSize is Button => {
  const { size, variant } = variantAndSize;

  return (
    variant in buttonStyles.variant &&
    size !== 'inherit' &&
    ['small', 'medium', 'large'].includes(size)
  );
};

const iconSizeConfig = {
  inherit: 's',
  small: 's',
  medium: 'm',
  large: 'l',
} as const;

const buttonPaddingConfig = (
  { size, variant }: Button,
  hasLeftIcon: boolean,
  hasRightIcon: boolean,
): { paddingX: PaddingX; paddingY: PaddingY } => {
  const IconPadding = (
    {
      small: 'baseNeg4',
      medium: 'baseNeg3',
      large: 'baseNeg2',
    } as const
  )[size];

  if (variant === 'icon') {
    return {
      paddingX: { x: IconPadding },
      paddingY: { y: IconPadding },
    };
  }

  const [paddingX, paddingY] = (
    {
      small: ['base', 'baseNeg3'],
      medium: ['basePos2', 'baseNeg1'],
      large: ['basePos3', 'basePos1'],
    } as const
  )[size];

  return {
    paddingX: {
      left: hasLeftIcon ? IconPadding : paddingX,
      right: hasRightIcon ? IconPadding : paddingX,
    },
    paddingY: { y: paddingY },
  };
};

export const isValidIconButton = (
  variant: ButtonOrLinkComponentProps['variant'],
  Icon: ElementType | undefined,
): Icon is ElementType => variant === 'icon';

export const isValidLoadingState = (
  loading: boolean,
  size: LinkOrButton['size'],
): size is ButtonSize => {
  if (loading && size === 'inherit') {
    throw new Error(`Wrong "size" used in combination with the "loading" prop`);
  }

  return loading && size !== 'inherit';
};

/**
 * Hook that returns a combination of
 * - styles
 * - padding separated into paddingX and paddingX values
 * - spacing to be passed to the <Box /> component
 * - iconSize to be passed to the <Icon /> component
 * - textVariant to be passed to the <Text /> component
 * - reset styles, either for anchor or button
 * to be used on either button or anchor elements.
 */
export const getClickableElementStyles = ({
  size,
  variant,
  disabled = false,
  hasLeftIcon = false,
  hasRightIcon = false,
}: GetClickableElementStylesOptions) => {
  const variantAndSize =
    variant in buttonStyles.variant
      ? ({
          variant,
          size: size === 'inherit' ? 'medium' : size,
        } as Button)
      : ({
          variant,
          size: size ?? 'small',
        } as Link);

  const iconSize = iconSizeConfig[variantAndSize.size];

  if (isButton(variantAndSize)) {
    return {
      styles: classFilter([
        buttonStyles.button,
        buttonStyles.variant[variantAndSize.variant],
        disabled && buttonStyles.disabled[variantAndSize.variant],
      ]),
      ...buttonPaddingConfig(variantAndSize, hasLeftIcon, hasRightIcon),
      iconSize,
      textVariant: textVariant[variantAndSize.size],
      spacing: variant === 'icon' ? 'none' : 'baseNeg2',
      resetStyles: linkStyles.linkAsButtonResets,
    } as const;
  }

  return {
    styles: classFilter([
      linkStyles.base,
      variant && linkStyles.variant[variantAndSize.variant],
      disabled && linkStyles.disabled,
    ]),
    paddingX: { x: 'none' },
    paddingY: { y: 'none' },
    iconSize,
    textVariant:
      variantAndSize.size === 'inherit'
        ? 'inherit'
        : linkTextVariant[variantAndSize.size ?? 'small'],
    spacing: 'baseNeg5',
    resetStyles: buttonStyles.buttonAsLinkResets,
  } as const;
};
