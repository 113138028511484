import type { BoxProps } from '../box/box';
import { Box, filterDisplayProps } from '../box/box';

type LayoutProps = Partial<
  Pick<
    BoxProps,
    | 'alignContent'
    | 'children'
    | 'spacing'
    | 'stack'
    | 'padding'
    | 'expand'
    | 'className'
    | 'Component'
    | 'style'
    | 'id'
    | 'variant'
    | 'size'
    | 'constrain'
  >
>;

const INTERNAL_Layout = ({
  children,
  spacing,
  padding,
  expand,
  className,
  Component,
  style,
  id,
  size,
  constrain,
  alignContent,
  variant,
  stack,
}: LayoutProps) => (
  <Box
    spacing={spacing}
    padding={padding}
    expand={expand}
    className={className}
    Component={Component}
    style={style}
    id={id}
    size={size}
    constrain={constrain}
    {...filterDisplayProps({ variant, alignContent, stack })}
  >
    {children}
  </Box>
);

export const Row = (props: Omit<LayoutProps, 'stack'>) => (
  <INTERNAL_Layout stack="row" {...props} />
);

export const Column = (props: Omit<LayoutProps, 'stack'>) => (
  <INTERNAL_Layout {...props} />
);
